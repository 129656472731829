// folders.js
export const folders = [
    {
      id: 1,
      name: "All template",
      templates: [
        { id: 2, name: "Template 1" },
        { id: 3, name: "Template 2" },
      ],
    },
    
    // ...other folders
  ];
  